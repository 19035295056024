/*************************/
/*     18. Copyright     */
/*************************/
.copyright {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  background-color: #1f1f1f;
  text-align: center;
}

.copyright p,
.copyright a {
  color: #dfe5ec;
  text-decoration: none;
}

