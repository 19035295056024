/*************************/
/*     09. Details 2     */
/*************************/
.tabs {
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}
.tabs .section-title {
  margin-bottom: 0.5rem;
}

.tabs h2,
.tabs .section-title {
  text-align: center;
}

.tabs .tabs-container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 2.75rem;
}

.tabs .nav-tabs {
  justify-content: left;
  margin-bottom: 1.125rem;
  border-bottom: none;
}

.tabs .nav-link {
  margin-right: 1.625rem;
  margin-bottom: 0.875rem;
  padding: 0;
  border: none;
  border-radius: 0.25rem;
  color: #c1cace;
  font: 700 1.375rem/1.375rem "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.tabs .nav-link:hover,
.tabs .nav-link.active {
  color: #cf291d;
  background-color: transparent !important;
}

.tabs .nav-link .fas {
  margin-right: 0.125rem;
  font-size: 0.875rem;
  vertical-align: 20%;
}

.tabs #tab-1 .progress-container {
  margin-top: 1.5rem;
}

.tabs #tab-1 .progress-container .title {
  margin-bottom: 0.25rem;
  color: #484a46;
  font: 600 0.875rem/1.25rem "Open Sans", sans-serif;
}

.tabs #tab-1 .progress {
  height: 1.375rem;
  margin-bottom: 1.125rem;
  border-radius: 0.125rem;
  background-color: #f9fafc;
}

.tabs #tab-1 .progress-bar {
  display: block;
  padding-left: 1.5rem;
  border-radius: 0.125rem;
  background: linear-gradient(to bottom right, #cf291d, #ff0000);
}

.tabs #tab-1 .progress-bar.first {
  width: 100%;
}

.tabs #tab-1 .progress-bar.second {
  width: 75%;
}

.tabs #tab-1 .progress-bar.third {
  width: 90%;
}

.tabs #tab-2 .list-unstyled {
  margin-top: 1.75rem;
  margin-bottom: 0;
  vertical-align: top;
}

.tabs #tab-2 .list-unstyled .media {
  margin-bottom: 2rem;
}

.tabs #tab-2 .list-unstyled .media-bullet {
  color: #cf291d;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
}

.tabs #tab-2 .list-unstyled .media-body {
  margin-left: 0.875rem;
}

.tabs #tab-3 .list-unstyled .fas {
  color: #cf291d;
  font-size: 0.5rem;
  line-height: 1.375rem;
}

.tabs #tab-3 .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.tabs #tab-3 .list-unstyled {
  margin-bottom: 1.75rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Details 2 */
  .tabs .tabs-container {
    max-width: 70%;
  }

  .tabs #tab-2 .list-unstyled {
    display: inline-block;
    /* width: 45%; */
    margin-top: 0;
  }

  .tabs #tab-2 .list-unstyled.first {
    margin-right: 2rem;
  }
  /* end of details 2 */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Details 2 */
  .tabs {
    height: 36.125rem;
  }

  .tabs .area-1 {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .tabs .tabs-container {
    max-width: 88%;
    margin-left: 2rem;
    padding-top: 6.75rem;
    padding-bottom: 0;
  }

  .tabs .nav-tabs {
    justify-content: flex-start;
  }
  /* end of details 2 */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Details 2 */
  .tabs .tabs-container {
    margin-right: 5rem;
    margin-left: auto;
  }

  .tabs #tab-2 .media-wrapper.first {
    margin-right: 2rem;
  }
  /* end of details 2 */
}
/* end of min-width width 1200px */
