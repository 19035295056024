body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*************************/
/*     02. Preloader     */
/*************************/
.spinner-wrapper {
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #1d1d1d;
}

.spinner {
	position: absolute;
	top: 50%; /* centers the loading animation vertically one the screen */
	left: 50%; /* centers the loading animation horizontally one the screen */
	width: 3.75rem;
	height: 1.25rem;
	margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */ 
	text-align: center;
}

.spinner > div {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	background-color: #fff;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0); }
	40% { -webkit-transform: scale(1.0); }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% { 
		-webkit-transform: scale(0);
		transform: scale(0);
	} 40% { 
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

/**************************/
/*     03. Navigation     */
/**************************/
.navbar-custom {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  background-color: #B50717;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  font: 600 0.875rem/0.875rem "Open Sans", sans-serif;
  transition: all 0.2s;
}

.navbar-custom .navbar-brand {
  font-size: 0;
}

.navbar-custom .navbar-brand.logo-image img {
  width: 7.0625rem;
  height: 2rem;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.navbar-custom .navbar-brand.logo-text {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font: 700 2.25rem/1.5rem "Montserrat", sans-serif;
  color: #fff;
  text-decoration: none;
}

.navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
    color: #BFBFBF;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
  display: block; /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  -webkit-animation: fadeDropdown 0.2s;
          animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@-webkit-keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-custom .dropdown-toggle:focus {
  /* removes dropdown outline on focus  */
  outline: 0;
}

.navbar-custom .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: #B50717;
}

.navbar-custom .dropdown-item {
  color: #fff;
  text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
  background-color: #B50717;
}

.navbar-custom .dropdown-item .item-text {
  font: 600 0.875rem/0.875rem "Open Sans", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
  color: #BFBFBF;
}

.navbar-custom .dropdown-items-divide-hr {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.75rem auto;
  border: none;
  background-color: #b5bcc4;
  opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .social-icons {
  display: none;
}

.navbar-custom .navbar-toggler {
  border: none;
  color: #fff;
  font-size: 2rem;
}

.navbar-custom
  button[aria-expanded="false"]
  .navbar-toggler-awesome.fas.fa-times {
  display: none;
}

.navbar-custom
  button[aria-expanded="false"]
  .navbar-toggler-awesome.fas.fa-bars {
  display: inline-block;
}

.navbar-custom
  button[aria-expanded="true"]
  .navbar-toggler-awesome.fas.fa-bars {
  display: none;
}

.navbar-custom
  button[aria-expanded="true"]
  .navbar-toggler-awesome.fas.fa-times {
  display: inline-block;
  margin-right: 0.125rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Navigation */
  .navbar-custom {
    padding: 2.125rem 1.5rem 2.125rem 2rem;
    box-shadow: none;
    background: transparent;
  }

  .navbar-custom .navbar-brand.logo-text {
    color: #fff;
  }

  .navbar-custom .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar-custom .nav-item .nav-link {
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  }

  .navbar-custom .nav-item .nav-link:hover,
  .navbar-custom .nav-item .nav-link.active {
    color: #CF291D;
  }

  .navbar-custom.top-nav-collapse {
    padding: 0.375rem 1.5rem 0.375rem 2rem;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    background-color: #B50717;
  }

  .navbar-custom.top-nav-collapse .navbar-brand.logo-text {
    color: #fff;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link {
    color: #fff;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
  .navbar-custom.top-nav-collapse .nav-item .nav-link.active {
    color: #BFBFBF;
  }

  .navbar-custom .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(0, 0, 0, 0.03);
  }

  .navbar-custom.top-nav-collapse .dropdown-menu {
    border-top: 0.125rem solid rgba(0, 0, 0, 0);
  }

  .navbar-custom .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navbar-custom .dropdown-items-divide-hr {
    width: 84%;
  }
  /* end of navigation */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Navigation */
  .navbar-custom .social-icons {
    display: block;
    margin-left: 0.5rem;
  }

  .navbar-custom .fa-stack {
    margin-left: 0.375rem;
    font-size: 0.8125rem;
  }

  .navbar-custom .fa-stack .hexagon {
    width: 1.625rem;
    height: 1.625rem;
    position: absolute;
    background: url(/static/media/hexagon-filled.de2786ad.svg) center center no-repeat;
    background-size: 1.625rem 1.625rem;
    transition: all 0.2s ease;
  }

  .navbar-custom .fa-stack:hover .hexagon {
    background: url(/static/media/hexagon-white.a8513328.svg) center center no-repeat;
    background-size: 1.625rem 1.625rem;
  }

  .navbar-custom .fa-stack-1x {
    font-size: 0.6875rem;
    line-height: 1.625rem;
    color: #fff;
    transition: all 0.2s ease;
  }

  .navbar-custom .fa-stack:hover .fa-stack-1x {
    color: #cf291d;
  }
  /* end of navigation */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Navigation */
  .navbar-custom {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .navbar-custom.top-nav-collapse {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  /* end of navigation */
}
/* end of min-width width 1200px */

/* Min-width width 1600px */
@media (min-width: 1600px) {
  /* Navigation */
  .navbar-custom {
    padding-right: 12rem;
    padding-left: 12rem;
  }

  .navbar-custom.top-nav-collapse {
    padding-right: 12rem;
    padding-left: 12rem;
  }
  /* end of navigation */
}
/* end of min-width width 1200px */

/*********************/
/*    04. Header     */
/*********************/
.header {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(/static/media/banner.0cf36898.jpg) center center no-repeat;
  background-size: cover;
}

.header .header-content {
  padding-top: 8rem;
  padding-bottom: 2.125rem;
  text-align: center;
}

.header .text-container {
  margin-bottom: 3rem;
}

.header h1 {
  margin-bottom: 0.5rem;
  color: #fff;
}

.header .p-large {
  margin-bottom: 2rem;
  color: #dfe5ec;
}

.header .btn-solid-lg {
  margin-right: 0.5rem;
  margin-bottom: 1.25rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Header */
  .header .header-content {
    padding-top: 13rem;
    padding-bottom: 7.5rem;
  }

  .header h1 {
    font: 700 3.5rem/4rem "Montserrat", sans-serif;
  }
  /* end of header */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Header */
  .header .header-content {
    padding-top: 14rem;
    padding-bottom: 15rem;
  }

  .header .text-container {
    margin-top: 6.5rem;
  }
  /* end of header */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Header */
  .header .header-content {
    padding-top: 14.75rem;
    padding-bottom: 17.5rem;
  }
  /* end of header */
}
/* end of min-width width 1200px */

/*************************/
/*     09. Details 2     */
/*************************/
.tabs {
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}
.tabs .section-title {
  margin-bottom: 0.5rem;
}

.tabs h2,
.tabs .section-title {
  text-align: center;
}

.tabs .tabs-container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 2.75rem;
}

.tabs .nav-tabs {
  justify-content: left;
  margin-bottom: 1.125rem;
  border-bottom: none;
}

.tabs .nav-link {
  margin-right: 1.625rem;
  margin-bottom: 0.875rem;
  padding: 0;
  border: none;
  border-radius: 0.25rem;
  color: #c1cace;
  font: 700 1.375rem/1.375rem "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.tabs .nav-link:hover,
.tabs .nav-link.active {
  color: #cf291d;
  background-color: transparent !important;
}

.tabs .nav-link .fas {
  margin-right: 0.125rem;
  font-size: 0.875rem;
  vertical-align: 20%;
}

.tabs #tab-1 .progress-container {
  margin-top: 1.5rem;
}

.tabs #tab-1 .progress-container .title {
  margin-bottom: 0.25rem;
  color: #484a46;
  font: 600 0.875rem/1.25rem "Open Sans", sans-serif;
}

.tabs #tab-1 .progress {
  height: 1.375rem;
  margin-bottom: 1.125rem;
  border-radius: 0.125rem;
  background-color: #f9fafc;
}

.tabs #tab-1 .progress-bar {
  display: block;
  padding-left: 1.5rem;
  border-radius: 0.125rem;
  background: linear-gradient(to bottom right, #cf291d, #ff0000);
}

.tabs #tab-1 .progress-bar.first {
  width: 100%;
}

.tabs #tab-1 .progress-bar.second {
  width: 75%;
}

.tabs #tab-1 .progress-bar.third {
  width: 90%;
}

.tabs #tab-2 .list-unstyled {
  margin-top: 1.75rem;
  margin-bottom: 0;
  vertical-align: top;
}

.tabs #tab-2 .list-unstyled .media {
  margin-bottom: 2rem;
}

.tabs #tab-2 .list-unstyled .media-bullet {
  color: #cf291d;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
}

.tabs #tab-2 .list-unstyled .media-body {
  margin-left: 0.875rem;
}

.tabs #tab-3 .list-unstyled .fas {
  color: #cf291d;
  font-size: 0.5rem;
  line-height: 1.375rem;
}

.tabs #tab-3 .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.tabs #tab-3 .list-unstyled {
  margin-bottom: 1.75rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Details 2 */
  .tabs .tabs-container {
    max-width: 70%;
  }

  .tabs #tab-2 .list-unstyled {
    display: inline-block;
    /* width: 45%; */
    margin-top: 0;
  }

  .tabs #tab-2 .list-unstyled.first {
    margin-right: 2rem;
  }
  /* end of details 2 */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Details 2 */
  .tabs {
    height: 36.125rem;
  }

  .tabs .area-1 {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .tabs .tabs-container {
    max-width: 88%;
    margin-left: 2rem;
    padding-top: 6.75rem;
    padding-bottom: 0;
  }

  .tabs .nav-tabs {
    justify-content: flex-start;
  }
  /* end of details 2 */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Details 2 */
  .tabs .tabs-container {
    margin-right: 5rem;
    margin-left: auto;
  }

  .tabs #tab-2 .media-wrapper.first {
    margin-right: 2rem;
  }
  /* end of details 2 */
}
/* end of min-width width 1200px */

/************************/
/*     12. Projects     */
/************************/
.filter {
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}

.filter .section-title {
  margin-bottom: 0.5rem;
}

.filter h2,
.filter .section-title {
  text-align: center;
}

.filter h2 {
  margin-bottom: 3.25rem;
}

.filter .button-group {
  text-align: center;
}

.filter .button-group a {
  display: inline-block;
  margin-right: 0.1875rem;
  margin-bottom: 0.5rem;
  margin-left: 0.1875rem;
  padding: 0.3125rem 1.375rem 0.25rem 1.375rem;
  border-radius: 0.25rem;
  background-color: #f1f4f7;
  color: #7b7e85;
  font: 700 0.75rem/1.25rem "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter .button-group a:hover {
  background-color: #cf291d;
  color: #fff;
}

.filter .button-group a.button.is-checked {
  background-color: #cf291d;
  color: #fff;
}

.filter .grid {
  margin-top: 1.25rem;
  border-radius: 0.375rem;
}

/* Hover Animation */
.filter .element-item {
  position: relative;
  float: left;
  overflow: hidden;
  width: 50%;
  background-color: #000; /* to hide 1px white margin in IE */
}

.filter .element-item img {
  max-width: 100%;
  margin: 0;
  transition: all 0.3s;
}

.filter .element-item:hover img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}
/* end of hover animation */

.filter .element-item .element-item-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  text-align: center;
  transition: all 0.3s;
}

.filter .element-item .element-item-overlay span {
  position: absolute;
  z-index: 3;
  top: 42%;
  right: 0;
  left: 0;
  color: #fff;
  font: 700 1.125rem/1.625rem "Montserrat", sans-serif;
}

/**********************************/
/*     13. Project Lightboxes     */
/**********************************/
.lightbox-basic {
  position: relative;
  max-width: 62.5rem;
  margin: 2.5rem auto;
  padding: 1.5625rem;
  border-radius: 0.25rem;
  background: #fff;
  text-align: left;
}

.lightbox-basic img {
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  border-radius: 0.25rem;
}

.lightbox-basic .line-heading {
  width: 3rem;
  height: 1px;
  margin-top: 0;
  margin-bottom: 0.625rem;
  margin-left: 0;
  border: none;
  background-color: #cf291d;
}

.lightbox-basic h6 {
  margin-bottom: 2rem;
}

.lightbox-basic .btn-solid-reg,
.lightbox-basic .btn-outline-reg {
  margin-right: 0.375rem;
  margin-bottom: 1rem;
}

.lightbox-basic a.mfp-close.as-button {
  position: relative;
  width: auto;
  height: auto;
  margin-right: 0.5rem;
  color: #484a46;
  opacity: 1;
}

.lightbox-basic a.mfp-close.as-button:hover {
  color: #f2f2f2;
}

.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.375rem;
  right: -0.375rem;
  width: 2.75rem;
  height: 2.75rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Projects */
  .filter .element-item {
    width: 25%;
  }

  .filter .element-item .element-item-overlay {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .filter .element-item:hover .element-item-overlay {
    opacity: 1;
  }
  /* end of projects */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Project Lightboxes */
  .lightbox-basic img {
    margin-bottom: 0;
  }

  .lightbox-basic h3 {
    margin-top: 0.375rem;
  }

  .lightbox-basic .btn-solid-reg,
  .lightbox-basic .btn-outline-reg {
    margin-bottom: 0;
  }
  /* end of project lightboxes */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Projects */
  .filter .element-item {
    width: 17.3125rem; /* to eliminate grid overflow problem on hard refresh */
    height: 16.875rem;
  }

  .filter .element-item .element-item-overlay span {
    top: 45%;
  }
  /* end of projects */
}
/* end of min-width width 1200px */

/************************/
/*     12. Projects     */
/************************/
.filter {
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}

.filter .section-title {
  margin-bottom: 0.5rem;
}

.filter h2,
.filter .section-title {
  text-align: center;
}

.filter h2 {
  margin-bottom: 3.25rem;
}

.filter .button-group {
  text-align: center;
}

.filter .button-group a {
  display: inline-block;
  margin-right: 0.1875rem;
  margin-bottom: 0.5rem;
  margin-left: 0.1875rem;
  padding: 0.3125rem 1.375rem 0.25rem 1.375rem;
  border-radius: 0.25rem;
  background-color: #f1f4f7;
  color: #7b7e85;
  font: 700 0.75rem/1.25rem "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter .button-group a:hover {
  background-color: #cf291d;
  color: #fff;
}

.filter .button-group a.button.is-checked {
  background-color: #cf291d;
  color: #fff;
}

.filter .grid {
  margin-top: 1.25rem;
  border-radius: 0.375rem;
}

/* Hover Animation */
.filter .element-item {
  position: relative;
  float: left;
  overflow: hidden;
  width: 50%;
  background-color: #000; /* to hide 1px white margin in IE */
}

.filter .element-item img {
  max-width: 100%;
  margin: 0;
  transition: all 0.3s;
}

.filter .element-item:hover img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}
/* end of hover animation */

.filter .element-item .element-item-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  text-align: center;
  transition: all 0.3s;
}

.filter .element-item .element-item-overlay span {
  position: absolute;
  z-index: 3;
  top: 42%;
  right: 0;
  left: 0;
  color: #fff;
  font: 700 1.125rem/1.625rem "Montserrat", sans-serif;
}

/**********************************/
/*     13. Project Lightboxes     */
/**********************************/
.lightbox-basic {
  position: relative;
  max-width: 62.5rem;
  margin: 2.5rem auto;
  padding: 1.5625rem;
  border-radius: 0.25rem;
  background: #fff;
  text-align: left;
}

.lightbox-basic img {
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  border-radius: 0.25rem;
}

.lightbox-basic .line-heading {
  width: 3rem;
  height: 1px;
  margin-top: 0;
  margin-bottom: 0.625rem;
  margin-left: 0;
  border: none;
  background-color: #cf291d;
}

.lightbox-basic h6 {
  margin-bottom: 2rem;
}

.lightbox-basic .btn-solid-reg,
.lightbox-basic .btn-outline-reg {
  margin-right: 0.375rem;
  margin-bottom: 1rem;
}

.lightbox-basic a.mfp-close.as-button {
  position: relative;
  width: auto;
  height: auto;
  margin-right: 0.5rem;
  color: #484a46;
  opacity: 1;
}

.lightbox-basic a.mfp-close.as-button:hover {
  color: #f2f2f2;
}

.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.375rem;
  right: -0.375rem;
  width: 2.75rem;
  height: 2.75rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Projects */
  .filter .element-item {
    width: 25%;
  }

  .filter .element-item .element-item-overlay {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .filter .element-item:hover .element-item-overlay {
    opacity: 1;
  }
  /* end of projects */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Project Lightboxes */
  .lightbox-basic img {
    margin-bottom: 0;
  }

  .lightbox-basic h3 {
    margin-top: 0.375rem;
  }

  .lightbox-basic .btn-solid-reg,
  .lightbox-basic .btn-outline-reg {
    margin-bottom: 0;
  }
  /* end of project lightboxes */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Projects */
  .filter .element-item {
    width: 17.3125rem; /* to eliminate grid overflow problem on hard refresh */
    height: 16.875rem;
  }

  .filter .element-item .element-item-overlay span {
    top: 45%;
  }
  /* end of projects */
}
/* end of min-width width 1200px */

/*************************/
/*     18. Copyright     */
/*************************/
.copyright {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  background-color: #1f1f1f;
  text-align: center;
}

.copyright p,
.copyright a {
  color: #dfe5ec;
  text-decoration: none;
}


