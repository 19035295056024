/************************/
/*     12. Projects     */
/************************/
.filter {
  padding-top: 3.5rem;
  padding-bottom: 2rem;
}

.filter .section-title {
  margin-bottom: 0.5rem;
}

.filter h2,
.filter .section-title {
  text-align: center;
}

.filter h2 {
  margin-bottom: 3.25rem;
}

.filter .button-group {
  text-align: center;
}

.filter .button-group a {
  display: inline-block;
  margin-right: 0.1875rem;
  margin-bottom: 0.5rem;
  margin-left: 0.1875rem;
  padding: 0.3125rem 1.375rem 0.25rem 1.375rem;
  border-radius: 0.25rem;
  background-color: #f1f4f7;
  color: #7b7e85;
  font: 700 0.75rem/1.25rem "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter .button-group a:hover {
  background-color: #cf291d;
  color: #fff;
}

.filter .button-group a.button.is-checked {
  background-color: #cf291d;
  color: #fff;
}

.filter .grid {
  margin-top: 1.25rem;
  border-radius: 0.375rem;
}

/* Hover Animation */
.filter .element-item {
  position: relative;
  float: left;
  overflow: hidden;
  width: 50%;
  background-color: #000; /* to hide 1px white margin in IE */
}

.filter .element-item img {
  max-width: 100%;
  margin: 0;
  transition: all 0.3s;
}

.filter .element-item:hover img {
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}
/* end of hover animation */

.filter .element-item .element-item-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  text-align: center;
  transition: all 0.3s;
}

.filter .element-item .element-item-overlay span {
  position: absolute;
  z-index: 3;
  top: 42%;
  right: 0;
  left: 0;
  color: #fff;
  font: 700 1.125rem/1.625rem "Montserrat", sans-serif;
}

/**********************************/
/*     13. Project Lightboxes     */
/**********************************/
.lightbox-basic {
  position: relative;
  max-width: 62.5rem;
  margin: 2.5rem auto;
  padding: 1.5625rem;
  border-radius: 0.25rem;
  background: #fff;
  text-align: left;
}

.lightbox-basic img {
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
  border-radius: 0.25rem;
}

.lightbox-basic .line-heading {
  width: 3rem;
  height: 1px;
  margin-top: 0;
  margin-bottom: 0.625rem;
  margin-left: 0;
  border: none;
  background-color: #cf291d;
}

.lightbox-basic h6 {
  margin-bottom: 2rem;
}

.lightbox-basic .btn-solid-reg,
.lightbox-basic .btn-outline-reg {
  margin-right: 0.375rem;
  margin-bottom: 1rem;
}

.lightbox-basic a.mfp-close.as-button {
  position: relative;
  width: auto;
  height: auto;
  margin-right: 0.5rem;
  color: #484a46;
  opacity: 1;
}

.lightbox-basic a.mfp-close.as-button:hover {
  color: #f2f2f2;
}

.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.375rem;
  right: -0.375rem;
  width: 2.75rem;
  height: 2.75rem;
}

/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* Projects */
  .filter .element-item {
    width: 25%;
  }

  .filter .element-item .element-item-overlay {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .filter .element-item:hover .element-item-overlay {
    opacity: 1;
  }
  /* end of projects */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Project Lightboxes */
  .lightbox-basic img {
    margin-bottom: 0;
  }

  .lightbox-basic h3 {
    margin-top: 0.375rem;
  }

  .lightbox-basic .btn-solid-reg,
  .lightbox-basic .btn-outline-reg {
    margin-bottom: 0;
  }
  /* end of project lightboxes */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Projects */
  .filter .element-item {
    width: 17.3125rem; /* to eliminate grid overflow problem on hard refresh */
    height: 16.875rem;
  }

  .filter .element-item .element-item-overlay span {
    top: 45%;
  }
  /* end of projects */
}
/* end of min-width width 1200px */
